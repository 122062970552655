
import { getAppList } from '@/plugins/setupMicro'
import { useUserStore } from '@/store/modules/user'
import { computed, defineComponent, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { DialogDataInterface } from 'wd-platform-library/lib/src/IDialog/src/IDialogInterFace'
import { IDialog } from 'wd-platform-library'
import { filterAppIdSet, sortAppIdSet } from '@/config/app';

export default defineComponent({
    name: 'app_home',
    setup () {
        const router = useRouter()
        const userData = computed(() => useUserStore().getUserInfo)

        const appList = computed((): ApplicationList[] => {
            if (!userData.value) {
                return []
            }
            const list = userData.value.applicationList.filter(
                (item) => item.platform === 'pc' && !filterAppIdSet.has(item.appId)
            )
            return list.sort((a, b) => {
                const indexA = sortAppIdSet.has(a.appId) ? sortAppIdSet.get(a.appId) as number : Infinity
                const indexB = sortAppIdSet.has(b.appId) ? sortAppIdSet.get(b.appId) as number : Infinity
                return indexA - indexB
            })
        })
        const dialogData = reactive<DialogDataInterface>({
            width: 800, // 弹框宽度
            show: false, // 弹框是否展示
            title: '全部应用', // 弹框名字
            cancel: () => {
                dialogData.show = false
            }
        })
        const onClick = (data: ApplicationList) => {
            dialogData.show = false
            if (data.routerType === 'router') {
                router.push(`/${data.appId}/`)
            } else {
                const app = getAppList().find((item: any) => item.name === data.appId)
                window.open(app.entry, '_blank')
            }
        }
        const otherFun = () => {
            dialogData.show = true
        }
        return {
            dialogData,
            otherFun,
            onClick,
            userData,
            appList
        }
    },
    components: {
        IDialog
    }
})
